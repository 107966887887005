import { defineComponent, reactive, onMounted, onUnmounted} from 'vue'
import { useRouter } from 'vue-router'
import { SET_REFUND_RESULT } from '@/utils/constant'
import './index.less'

export default defineComponent({
    setup () {

        const router = useRouter()

        const state: any = reactive({
            info: {}
        })

        onMounted(() => {
            _init()
        })

        onUnmounted(() => {
            const { id } = state.info
            localStorage.removeItem(`${id}_pay`)
            localStorage.removeItem(SET_REFUND_RESULT)
        })

        const _init = () => {
            let res:any = localStorage.getItem(SET_REFUND_RESULT)
            if (res) {
                res = JSON.parse(res)
                state.info = { ...res.info }
            }
        }

        const onBack = () => {
            router.push({path: '/refund'})
        }
        

        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>申请退款</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="refund-result-box">
                    <div class="refund-result-header">
                        <img src="https://img.fanwoon.com/weibiaoti38%402x.png" />
                        <div class="refund-mark">退款申请已提交</div>
                        <div class="refund-result-tips">预计30个工作日内，通过银行转账方式退款至下列账户，请以银行实际到账时间为准：</div>
                    </div>
                    <div class="refund-amount-info">
                        <div><span class="item-label">开户银行：</span><span>{state.info.bank}</span></div>
                        <div><span class="item-label">支行名称：</span><span>{state.info.subbranch}</span></div>
                        <div><span class="item-label">银行账号：</span><span>{state.info.bankAccount}</span></div>
                        <div><span class="item-label">开户人姓名：</span><span>{state.info.ownerName}</span></div>
                        <div><span class="item-label">手机号码：</span><span>{state.info.ownerPhone}</span></div>
                        <div><span class="item-label">留言：</span><span>{state.info.remark}</span></div>
            
                    </div>
                </div>
                <div class="refund-result-footer-box">
                    <button class="sub-btn" onClick={onBack}>返回</button>
                </div>
            </div>
        )
    }
})